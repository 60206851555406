import { Component, Fragment } from "react";
import Fade from 'react-reveal/Fade';

const animationList = [
    "My name is Luigi Gesualdo",
    "This is my website",
    "I don't know what to do with these animations",
    "Ehmmm",
    "Ehmmmmmmmm",
    "You are still here",
    "Ehmmm",
    "You better find something more useful to do"
  ]
  

export default class ReactReveal extends Component{
    render(){
        return (
        <Fragment>
          {animationList.map((item, key) => (
            <div style={styles.block} key={key}>
              <Fade top>
                <h1 style={styles.title}>{`${item}`}</h1>
              </Fade>
            </div>
          ))}
        </Fragment>
        )
    }
}

const styles = {
    block: {
    display: 'flex',
    width: "100%",
    height: "100%",
    alignItems: 'center', 
    justifyContent: 'center', 
    background: '#282c34', 
    borderBottom: '1px solid',
    },
    title: {
    fontSize: 100,
    textAlign: 'center', 
    fontWeight: 100,
    color: '#fff',
    fontFamily: 'Lato, sans-serif',
    },
  };