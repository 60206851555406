import './App.css';
import { TypeAnimation } from 'react-type-animation';


function App() {
  return (
        <TypeAnimation className=''
          sequence={[
            'Hi.',
            1000,
            'I hope you are fine.',
            2000,
            'You are loved and needed in this world.',
          ]}
          wrapper="div"
          cursor={true}
          repeat={0}
          style={{ fontSize: '2em' }}
          deletionSpeed={65}
          speed={55}
        />
  );
}



export default App;
